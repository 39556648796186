<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage" />
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="入库日期">
                <a-range-picker @change="onDateChange" style="width: 100%"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="出库日期">
                <a-range-picker @change="onOutDateChange" style="width: 100%"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务来源">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择业务来源"
                  style="width: 100%"
                  v-model="queryParam.source"
                >
                  <a-select-option v-for="op in sourceOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务类型">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择业务类型"
                  style="width: 100%"
                  v-model="queryParam.business_type"
                >
                  <a-select-option v-for="op in bizTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="报关单号">
                <a-input v-model="queryParam['report_num']" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="货物状态">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择货物状态"
                  style="width: 100%"
                  v-model="queryParam.commodity_type"
                >
                  <a-select-option v-for="op in commodityOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="计划号">
                <a-input v-model="queryParam['plan_id']" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="接单日期">
                <a-range-picker @change="onReceiveDateChange" style="width: 100%"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="现金收款日期">
                <a-range-picker @change="onDateChange4" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="现金收款状态">
                <a-select allowClear placeholder="请选择收款状态" style="width: 100%" v-model="queryParam.cash_status">
                  <a-select-option v-for="op in cashStatusOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
        <a-button :disabled="!selectedRowKeys.length" @click="confirmCheck()">审核</a-button>
        <a-button type="primary" @click="startCashCharge">现金收费</a-button>
      </div>

      <s-table
        ref="table"
        size="middle"
        rowKey="id"
        :filterColumn="true"
        :columns="columns"
        :data="loadData"
        :scroll="{ x: 'max-content' }"
        :alert="{
          show: true,
          msg: showTotal
        }"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
        @dblclick="handleEdit"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="feeItem" slot-scope="fee">
          <div v-for="item in fee" :key="item.id">
            <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
              {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
            </a-tag>
            <span v-if="item.charge_type === 1">
              {{ item.fee_name }}
              <a-tag color="#A940FF" style="margin-bottom:2px;">
                现金：{{ item.amount }}
              </a-tag>
            </span>
            <span v-else-if="item.charge_type === 2">
              {{ item.fee_name }}
              <a-tag color="#DADADA" style="margin-bottom:2px;">
                月结：{{ item.amount }}
              </a-tag>
            </span>
          </div>
        </div>
        <div slot="cashRecordList" slot-scope="text">
          <span style="display: block" v-for="item in text" :key="item.id">
            <span v-if="item.cash_status === 0">
              <a-tag color="#1890ff" style="margin-bottom: 2px"> 待支付 </a-tag>
              {{ `金额:${item.amount}` }}
            </span>
            <span v-if="item.cash_status === 1">
              <a-tag color="#4cd964" style="margin-bottom: 2px"> 支付完成 </a-tag>
              {{ `金额:${item.amount} 交易号:${item.trx_id} 交易时间:${item.pay_date}` }}
            </span>
            <span v-if="item.cash_status === 2">
              <a-tag color="#faad14" style="margin-bottom: 2px"> 支付中 </a-tag>
              {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
            </span>
            <span v-if="item.cash_status === 3">
              <a-tag color="#ff4d4f" style="margin-bottom: 2px"> 支付失败 </a-tag>
              {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
            </span>
          </span>
        </div>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="openRecordTicket(record)">查看小票</a>
          </template>
        </span>
      </s-table>

      <a-modal
        :visible="ticketVisible"
        :confirmLoading="ticketLoading"
        :width="1520"
        :maskClosable="false"
        title="小票列表"
        @cancel="ticketVisible = false"
        :footer="null"
      >
        <a-table
          rowKey="id"
          :columns="ticketColumns"
          :data-source="ticketData"
          :pagination="false"
        >
          <span slot="serial" slot-scope="text, record, index">{{ (index + 1) }}</span>
          <div slot="feeInfo" slot-scope="text">
            <span style="display: block;" v-for="(item, index) in text" :key="index">{{ item.fee_name }}:{{ item.amount }}</span>
          </div>
          <div slot="cashRecordList" slot-scope="text">
            <span style="display: block" v-for="item in text" :key="item.id">
              <span v-if="item.cash_status === 0">
                <a-tag color="#1890ff" style="margin-bottom: 2px"> 待支付 </a-tag>
                {{ `金额:${item.amount}` }}
                <a @click="delQRCode(item)">取消交易</a>
                <img :src="item.pay_info" style="width: 150px; height: 150px; border: 1px solid #f1f1f1" />
              </span>
              <span v-if="item.cash_status === 1">
                <a-tag color="#4cd964" style="margin-bottom: 2px"> 支付完成 </a-tag>
                {{ `金额:${item.amount} 交易号:${item.trx_id} 交易时间:${item.pay_date}` }}
              </span>
              <span v-if="item.cash_status === 2">
                <a-tag color="#faad14" style="margin-bottom: 2px"> 支付中 </a-tag>
                {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
              </span>
              <span v-if="item.cash_status === 3">
                <a-tag color="#ff4d4f" style="margin-bottom: 2px"> 支付失败 </a-tag>
                {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
              </span>
            </span>
          </div>
          <div slot="action" slot-scope="text, record">
            <a @click="printTicket(record)">打印</a>
            <a-divider type="vertical" />
            <a @click="delTicket(record)">删除</a>
          </div>
        </a-table>
      </a-modal>

      <a-modal
        :visible="printVisible"
        :width="420"
        :maskClosable="false"
        title="打印结费单"
        @cancel="printVisible = false"
      >
        <div id="printCmsBill">
          <CmsBill :data="printData"></CmsBill>
        </div>
        <template slot="footer">
          <a-button key="submit" type="primary" v-print="printCmsBill">确认打印</a-button>
        </template>
      </a-modal>

      <a-modal
        :visible="chargeTicketVisible"
        :confirmLoading="chargeTicketLoading"
        :width="1520"
        :maskClosable="false"
        title="收费小票列表"
        @cancel="chargeTicketVisible = false"
        :footer="null"
      >
        <a-table
          rowKey="id"
          :columns="chargeTicketColumns"
          :data-source="chargeTicketData"
          :pagination="false"
        >
          <span slot="serial" slot-scope="text, record, index">{{ (index + 1) }}</span>
          <div slot="feeInfo" slot-scope="text">
            <span style="display: block;" v-for="(item, index) in text" :key="index">{{ item.fee_name }}:{{ item.amount }}</span>
          </div>
          <div slot="action" slot-scope="text, record">
            <a @click="delChargeTicket(record)">删除</a>
          </div>
        </a-table>
        <a-card style="margin-bottom: 10px">
          <a-radio-group name="radioGroup" v-model="dealType">
            <a-radio :value="1">微信</a-radio>
            <a-radio :value="2">支付宝</a-radio>
          </a-radio-group>
          <a-button type="primary" @click="createQRCode">
            创建收款码
          </a-button>
          <div v-if="payInfo" class="charge-code">
            <img :src="payInfo" style="width: 200px; height: 200px;border: 1px solid #f1f1f1">
          </div>
        </a-card>
      </a-modal>
    </a-card>
  </keep-alive>
</template>
<script>
import { STable } from '@/components'
import moment from 'moment'
import { getCommonOptions } from '@/api/common'
import CmsBill from '@/views/wms/bill/CmsBill.vue'
import {
  getWmsCmsInfoPage,
  getCmsMetaOption,
  confirmCmsInfo,
  getCmsTicketInfo,
  delCmsTicket,
  printCmsTicket,
  getUnChargeTicket
} from '@/api/cms'
import {
  createWmsCashQRCode,
  delWmsCashDeal,
} from '@/api/wms'
export default {
  components: {
    STable,
    CmsBill
  },
  data() {
    return {
      sourceOps: [],
      bizTypeOps: [],
      commodityOps: [],
      boxOps: [],
      queryParam: {
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '业务来源',
          dataIndex: 'source_name'
        },
        {
          title: '报关单号',
          dataIndex: 'report_num'
        },
        {
          title: '计划号',
          dataIndex: 'plan_id'
        },
        {
          title: '报关单位',
          dataIndex: 'customs_name'
        },
        {
          title: '品名',
          dataIndex: 'total_name'
        },
        {
          title: '查验费用',
          dataIndex: 'cms_fees',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '仓储费用',
          dataIndex: 'wms_fees',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '合计',
          dataIndex: 'total'
        },
        {
          title: '现金收费',
          dataIndex: 'cash_record_list',
          scopedSlots: { customRender: 'cashRecordList' }
        },
        {
          title: '工号',
          dataIndex: 'worker_num'
        },
        {
          title: '入库日期',
          dataIndex: 'purchase_date'
        },
        {
          title: '货物状态',
          dataIndex: 'commodity_type_name'
        },
        {
          title: '箱型',
          dataIndex: 'box_type_name'
        },
        {
          title: '布控部门',
          dataIndex: 'control_department'
        },
        {
          title: '联系人',
          dataIndex: 'contact_name'
        },
        {
          title: '联系电话',
          dataIndex: 'contact_phone'
        },
        {
          title: '业务类型',
          dataIndex: 'business_name'
        },
        {
          title: '接单日期',
          dataIndex: 'receive_time',
          customRender: (text) => {
            return text && moment(text).format('YYYY-MM-DD');
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      currentData: null,
      loadData: parameter => {
        return getWmsCmsInfoPage(Object.assign(parameter, this.queryParam)).then(res => {
          this.showTotal = res.total_row
          return res
        })
      },
      ticketColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '查验费用',
          dataIndex: 'fee_info_list',
          scopedSlots: { customRender: 'feeInfo' }
        },
        {
          title: '合计',
          dataIndex: 'total'
        },
        {
          title: '小票状态',
          dataIndex: 'ticket_status',
          customRender: text => {
            return text === 0 ? '未使用' : '已使用'
          }
        },
        {
          title: '交易信息',
          dataIndex: 'cash_record_list',
          scopedSlots: { customRender: 'cashRecordList' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      ticketData: [],
      ticketVisible: false,
      ticketLoading: false,
      chargeTicketColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '计划号',
          dataIndex: 'order_num',
          align: 'center'
        },
        {
          title: '费用',
          dataIndex: 'fee_info_list',
          scopedSlots: { customRender: 'feeInfo' }
        },
        {
          title: '合计',
          dataIndex: 'total'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      chargeTicketData: [],
      chargeTicketVisible: false,
      chargeTicketLoading: false,
      cashStatusOps: [
        { value: 0, name: '待支付' },
        { value: 1, name: '支付完成' },
        { value: 2, name: '支付中' },
        { value: 3, name: '支付失败' },
      ],
      printVisible: false,
      printCmsBill: {
        id: `printCmsBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      },
      printData: [],
      showTotal: '',
      dealType: 1,
      payInfo: undefined
    }
  },
  watch: {
    $route: function(newRoute) {
      if (newRoute.name === 'WmsCmsBiz' && this.queryParam) {
        newRoute.meta.title = '查验业务'
        this.$nextTick(_ => {
          this.$refs.table.refresh()
        })
      }
    }
  },
  created() {
    getCmsMetaOption('source').then(v => {
      this.sourceOps = v
    })
    getCmsMetaOption('business_type').then(v => {
      this.bizTypeOps = v
    })
    getCmsMetaOption('commodity_type').then(v => {
      this.commodityOps = v
    })
    getCommonOptions('box_info').then(v => {
      this.boxOps = v
    })
  },
  methods: {
    moment,
    printInfo() {
      this.printVisible = true
    },
    handleEdit(record) {
      this.currentData = record
      this.$router.push({
        name: 'WmsCmsBizEdit',
        params: record,
        query: {
          disabled: false
        }
      })
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['enter_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['enter_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['enter_start_date'] = null
        this.queryParam['enter_end_date'] = null
      }
    },
    onOutDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['exit_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['exit_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['exit_start_date'] = null
        this.queryParam['exit_end_date'] = null
      }
    },
    onReceiveDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['receive_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['receive_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['receive_start_date'] = null
        this.queryParam['receive_end_date'] = null
      }
    },
    onDateChange4(date, dateString) {
      if (date[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['cash_start_date'] = startDate
        this.queryParam['cash_end_date'] = endDate
      } else {
        this.queryParam['cash_start_date'] = null
        this.queryParam['cash_end_date'] = null
      }
    },
    confirmCheck() {
      confirmCmsInfo(this.selectedRowKeys).then(res => {
        if (res) {
          this.$notification['success']({
            message: '提示',
            description: '审核成功'
          })
          this.$refs.table.clearSelected()
          this.printData = res
          this.printVisible = true
        }
        this.$refs.table.refresh(true)
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    openRecordTicket(record) {
      this.currentData = record
      this.ticketVisible = true
      this.getRecordTicket()
    },
    getRecordTicket() {
      getCmsTicketInfo({
        id: this.currentData.id
      }).then(res => {
        this.ticketData = res
      })
    },
    delTicket(record) {
      this.ticketLoading = true
      delCmsTicket({
        ticketId: record.id
      }).then(res => {
        if (res) {
          this.$notification['success']({
            message: '提示',
            description: '删除成功.'
          })
          this.getRecordTicket()
          this.$refs.table.refresh(false)
        }
      }).finally(_ => {
        this.ticketLoading = false
      })
    },
    printTicket(record) {
      const ticketIdList = []
      ticketIdList.push(record.id)
      printCmsTicket(ticketIdList).then(res => {
        this.printData = res
        this.printVisible = true
      })
    },
    startCashCharge() {
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        this.payInfo = undefined
        this.dealType = 1
        this.getChargeTicket()
        this.chargeTicketVisible = true
      } else {
        this.$notification['warn']({
          message: '提示',
          description: '请选择要支付的订单.'
        })
      }
    },
    delChargeTicket(record) {
      if (this.payInfo) {
        return
      }
      for (let i = 0; i < this.chargeTicketData.length; i++) {
        if (this.chargeTicketData[i].id == record.id) {
          this.chargeTicketData.splice(i, 1)
        }
      }
    },
    getChargeTicket() {
      getUnChargeTicket(this.selectedRowKeys).then(res => {
        if (res) {
          this.chargeTicketData = res
        }
      })
    },
    createQRCode() {
      createWmsCashQRCode({
        id_list: this.chargeTicketData.map(item => item.id),
        deal_type: this.dealType
      }).then(res => {
        if (res) {
          this.payInfo = res
        }
      })
    },
    delQRCode(record) {
      delWmsCashDeal({
        cashId: record.id
      }).then(res => {
        if (res) {
          this.getRecordTicket()
        }
      })
    }
  }
}
</script>

<style scoped>
.charge-code {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
</style>